<footer>
  <div class="footer-container row">

    <div class="left-col col-md-6">
      <!-- <img src="/assets/images/msaii-logo-darkblue.png" alt="logo" class="logo"> -->
      <div class="social-media pl-2">

        <li>
          <a href="#" class="icoFacebook" title="Facebook">
            <i class="fa fa-facebook" style="color: rgb(0, 184, 215);"></i>
          </a>
        </li>
        <li>
          <a href="#" class="icoTwitter" title="Twitter">
            <i class="fa fa-twitter" style="color: rgb(0, 184, 215);"></i>
          </a>
        </li>
        <li>
          <a href="#" class="icoInstagram" title="instagram">
            <i class="fa fa-instagram" style="color: rgb(0, 184, 215);"></i>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/msaii/" class="icoLinkedin" title="Linkedin" target="_blank">
            <i class="fa fa-linkedin" style="color: rgb(0, 184, 215);"></i>
          </a>
        </li>
        

      </div>
    </div>

    <div class="right-col col-md-6">
      <img src="assets/homeimages/e-verify-logo.jpg" class="e-verify"/>
      <span class='pl-3'> MSAII Copyright © 2024, All Rights Reserved</span>
    </div>

  </div>



</footer>