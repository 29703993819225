<section id="contactus" class="text-white min-banner">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 contactUsDiv">
        <!-- <div class="contact-text">
               Contact Us
               </div> -->
        <div class="banner-text">
          <strong
            class="contact__one lato-bold got_a_project"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            Got a project ?
          </strong>
          <p
            class="contact__two lato-reg"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            Tell us everything
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="keepInTouch" class="keepInTouchContainer">
  <div class="keepInTouchWrapper">
    <div class="container-md">
      <!-- <div class="heroContainer mb-2">
            </div> -->
      <div class="contactInfo">
        <div class="row">
          <div class="col-lg-4">
            <h6 class="text-muted mediumTitle">DROP US A LINE</h6>
          </div>
          <div class="col-lg-4 center">
            <div class="infoWrapper">
              <p class="infoTitle m-0">PHONE</p>
              <p class="infoContent m-0">+1 (612) 479-9196</p>
            </div>
            <div class="infoWrapper">
              <p class="infoTitle m-0">GENERAL</p>
              <p class="infoContent m-0">info@msaii.com</p>
            </div>
          </div>
          <div class="col-lg-4 end">
            <div class="infoWrapper mb-2">
              <p class="infoTitle m-0">CAREERS</p>
              <p class="infoContent m-0">careers@msaii.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactLocations usa">
      <p class="countryName m-0">USA</p>
      <div class="contactLocationWrapper">
        <div class="container">
          <div class="locationContainer py-5">
            <div class="row">
              <div class="col-sm-4">
                <h3>POWELL, OH</h3>
              </div>
              <div class="col-sm-4 horizontalCenter py-2">
                <p class="m-0">
                  9775 Fairway Drive,
                  <br />
                  SUITE - D,
                  <br />
                  Powell, OH-43605
                  <br>
                </p>
              </div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection bg-bl">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>TROY, MI</h3>
              </div>
              <div class="col-sm-4 horizontalCenter">
                <!-- <p class="m-0">9775 Fairway Drive,
                           <br>
                           SUITE - D,
                           <br>
                           Powell, OH-43605
                           </p> -->
              </div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection bg-bl">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>SAN JONES, CA</h3>
              </div>
              <div class="col-sm-4 horizontalCenter">
                <!-- <p class="m-0">9775 Fairway Drive,
                           <br>
                           SUITE - D,
                           <br>
                           Powell, OH-43605
                           </p> -->
              </div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection bg-bl">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactLocations india position-relative">
      <p class="countryName m-0">INDIA</p>
      <div class="contactLocationWrapper">
        <div class="container">
          <div class="locationContainer py-5">
            <div class="row">
              <div class="col-sm-4">
                <h3>CHENNAI, TN</h3>
              </div>
              <div class="col-sm-4 horizontalCenter">
                <p class="m-0">
                  No 51, 4th Floor, Tower-C,
                  <br />
                  Tek Medows, OMR Road,
                  <br />
                  Sholinganallur, Chennai,
                  <br />
                  Tamilnadu - 600119
                </p>
              </div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>BENGALURU, KA</h3>
              </div>
              <div class="col-sm-4 horizontalCenter"></div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>PUNE, MH</h3>
              </div>
              <div class="col-sm-4 horizontalCenter">
                <!-- <p class="m-0">9775 Fairway Drive,
                           <br>
                           SUITE - D,
                           <br>
                           Powell, OH-43605
                           </p> -->
              </div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactLocations india position-relative mt-2">
      <p class="countryName  Vietnam ms-0" style="top: 30%">VIETNAM</p>
      <div class="contactLocationWrapper">
        <div class="container">
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>Ho chi Minh city</h3>
              </div>
              <div class="col-sm-4"></div>
              <div class="col-sm-4">
                <div class="getDirection">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactLocations jpn">
      <p class="countryName top m-0">JAPAN</p>
      <span class="countryName btm m-0">EUROPE</span>
      

      <div class="contactLocationWrapper">
        <div class="container">
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>TOKYO, JP</h3>
              </div>
              <div class="col-sm-4 horizontalCenter"></div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection bg-bl">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="locationContainer">
            <div class="row">
              <div class="col-sm-4">
                <h3>GERMANY, GMBH</h3>
              </div>
              <div class="col-sm-4 horizontalCenter">
                <!-- <p class="m-0">9775 Fairway Drive,
                           <br>
                           SUITE - D,
                           <br>
                           Powell, OH-43605
                           </p> -->
              </div>
              <div class="col-sm-4 verticalCenter text-right">
                <!-- <div class="getDirection">
                           <span>GET DIRECTIONS</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                           </svg>
                        </div> -->
                <div class="getDirection bg-bl">
                  <div class="button">
                    <a href="" class="link">GET DIRECTIONS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</section>