<section id="industries" class="pt-5">
    <div class="container-fluid px-5">
  
      <div class="row">
        <div class="col-lg-12 mb-2">
          <div class="section-title" data-aos="fade-down" data-aos-duration="2000">
            <h2 class="title_color logo-color">Industries we serve</h2>
            <p>Vertical and horizontal market – we dive deep across industries.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid px-5">
      <div class="row">

            <div class="col-lg-4 col-xl-4 col-xll-4 col-12">
                <div class="industries_content">
                    <div class="industries_image">
                        <img src="assets/images/auto-1.png" alt="stock_illustration" />
                    </div>
                    <div class="content">
                        <p class="title">Automotive</p>
                        <p class="description">
                        The global automotive industry is always in flux, as new models & designs alter the shape and
                        performance of automobiles.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','automotive']" class="link">Explore</a>
                        </div>
                    </div>
                </div>                    
            </div>

            <div class="col-lg-4 col-xl-4 col-xll-4 col-12">
                <div class="industries_content">
                    <div class="industries_image">
                        <img src="assets/images/aerospace.png" alt="stock_illustration" />
                    </div>
                    <div class="content">
                        <p class="title">Aerospace</p>
                        <p class="description">
                            The global automotive industry is always in flux, as new models &
                            designs alter the shape and performance of Aerospace.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','aerospace']" class="link">Explore</a>
                        </div>
                    </div>
                </div>                    
            </div>

            <div class="col-lg-4 col-xl-4 col-xll-4 col-12">
                <div class="industries_content">
                    <div class="industries_image">
                        <img src="assets/images/auto-2.png" alt="stock_illustration" />
                    </div>
                    <div class="content">
                        <p class="title">Digital Manufacturing</p>
                        <p class="description">
                            Digital manufacturing is an integral part of product lifecycle management (PLM)
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','digital-industry']" class="link">Explore</a>
                        </div>
                    </div>
                </div>                    
            </div>

            <div class="col-lg-4 col-xl-4 col-xll-4 col-12">
                <div class="industries_content">
                    <div class="industries_image">
                        <img src="assets/images/industires/semiconductor.png" alt="stock_illustration" />
                    </div>
                    <div class="content">
                        <p class="title">Semiconductor</p>
                        <p class="description">
                            A semiconductor material has an electrical conductivity value falling between that of a conductor.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="link">Explore</a>
                        </div>
                    </div>
                </div>                    
            </div>

            <div class="col-lg-4 col-xl-4 col-xll-4 col-12">
                <div class="industries_content">
                    <div class="industries_image">
                        <img src="assets/images/industires/webdevelop.png" alt="stock_illustration" />
                    </div>
                    <div class="content">
                        <p class="title">Digital Industries</p>
                        <p class="description">
                            The global automotive industry is always in flux, as new models & designs alter the shape and
                            performance of automobiles.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','web-development']" class="link">Explore</a>
                        </div>
                    </div>
                </div>                    
            </div>

            <div class="col-lg-4 col-xl-4 col-xll-4 col-12">
                <div class="industries_content">
                    <div class="industries_image">
                        <img src="assets/images/energy.png" alt="stock_illustration" />
                    </div>
                    <div class="content">
                        <p class="title">Energy</p>
                        <p class="description">
                            The global automotive industry is always in flux, as new models &
                            designs alter the shape and performance of automobiles.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','energy']" class="link">Explore</a>
                        </div>
                    </div>
                </div>                    
            </div>
      </div>
    </div>
   
  </section>